//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

// Colors
$primary: #000; // Changing primary color
$secondary: #0199EC;
$secondary50: #0199ECa1;
$success: #017254;
$danger: #f11d3e;
$info: #27afbf;
$warning: #fe6b00;
$light: #f6f6f6;
$dark: #4b4b4b;
$terraCotta: #e26060;
$smokyBlack: #120202;
$maroon: #730f0f;
$brown: #a44200;

$blue: #5063af;
$tangerine: #ee8207;

// BODY
$body-bg: #f8f8f8;
$body-color: #000;

// Gray
$white: #fff;
$gray-50: #f2f2f2;
$gray-100: #d8d8d8; // $gray-lightest
$gray-200: #d7d7d7; // $gray-lighter
$gray-300: #989898; // $gray-light
$gray-400: #5f5f5f;
$gray-500: #4e5154;
$gray-600: #b8c2cc; // $gray
$gray-700: #22292f;
$gray-800: #333333; // $gray-dark
$gray-900: #231f20;
$black: #000; //

// Gradients

$secondaryGradient: linear-gradient(
  43.96deg,
  rgba(1, 153, 236, 0.5) 2.91%,
  rgba(1, 153, 236, 0.1) 94.71%
);

$secondary-700: #004a8f;
$secondary-600: #0065b3;
$secondary-500: #0084d6;
$secondary-300: #49bff2;
$secondary-200: #94dff7;
$secondary-1OO: #bbecfa;

//Theme colors

$theme-colors: (
  "secondary-700": $secondary-700,
  "secondary-600": $secondary-600,
  "secondary-500": $secondary-500,
  "secondary-300": $secondary-300,
  "secondary-200": $secondary-200,
  "secondary-1OO": $secondary-1OO,
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "danger": $danger,
  "info": $info,
  "warning": $warning,
  "light": $light,
  "dark": $dark,
  "terraCotta": $terraCotta,
  "smokyBlack": $smokyBlack,
  "maroon": $maroon,
  "brown": $brown,
);
